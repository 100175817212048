import { Outlet, BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group';


import Login from "./components/Login";
import Navbar from "./components/Navbar";
import Register from "./components/Register";

import Dashboard from "./components/Dashboard";

function App() {
    return (
        <BrowserRouter>
            <AnimatedRoutes />
        </BrowserRouter>
    );
}

function AnimatedRoutes() {
    const location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition key={location.key} classNames="page" timeout={500}>
                <Routes location={location}>
                    <Route path="/register" element={<Register />} />

                    <Route path="/" element={<Dashboard />} />
                    <Route path="/" element={<LayoutsWithNavbar />}>

                    </Route>
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    );
}

function LayoutsWithNavbar() {
    return (
        <>
            <div className="App">
                <Navbar />
                <Outlet />
            </div>
        </>
    );
}

export default App;
