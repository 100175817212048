import React from 'react'
import {useNavigate} from "react-router-dom";
import { Link } from 'react-router-dom';
const Navbar = () => {

    const history = useNavigate();
    const Logout = (e) => {
        e.preventDefault();
        localStorage.clear('accessToken');

        localStorage.clear('refreshToken');

        localStorage.clear('fio');

        localStorage.clear('username');

        history("/");
    }



    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <img src='/images/Logo-header.svg'/>
            </div>
            <div className="navbar-links">
                <a onClick={Logout}>Выйти</a>
            </div>
        </nav>
    )
}

export default Navbar
