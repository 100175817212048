import * as React from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {render} from "@testing-library/react";
import jwt_decode from "jwt-decode";


const Dashboard = () => {



    const [searchParams, setSearchParams] = useSearchParams();

    const [result, setResult] = useState([]);

    const [orderID, setOrderID] = useState(1);
    const [orderTime, setOrderTime] = useState(0);
    const [orderRes, setOrderRes] = useState(0);

    const history = useNavigate();

    //if ((localStorage.getItem('accessToken') === null)||(localStorage.getItem('accessToken') === '')) history("/");

    useEffect(() => {
        download();

    }, []);

    function order_by_id  (){
        setOrderTime(0);
        setOrderRes(0);
        switch (orderID){
            case 0:
                setOrderID(1);
                download('-global_finish_time');
                break;
            case 1:
                setOrderID(2);

                download('global_finish_time');
                break;
            case 2:
                setOrderID(1);

                download('-global_finish_time');
                break;
        }

    }

    function order_by_res (){

        setOrderTime(0);
        setOrderID(0);
        switch (orderRes){
            case 0:
                setOrderRes(1);
                download('-flight_result');
                break;
            case 1:
                setOrderRes(2);

                download('flight_result');
                break;
            case 2:
                setOrderRes(1);

                download('-flight_result');
                break;
        }
    }
    function order_by_time (){
        setOrderRes(0);
        setOrderID(0);
        switch (orderTime){
            case 0:
                setOrderTime(1);
                download('-flight_time');
                break;
            case 1:
                setOrderTime(2);

                download('flight_time');
                break;
            case 2:
                setOrderTime(1);

                download('-flight_time');
                break;
        }

    }

    const download = async (order='-global_finish_time')  =>  {
        //const currentDate = new Date();
        //if (localStorage.getItem('expire')<currentDate) await refreshToken();

        const response = await axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/results/leaderboard/',
            {
                    order_by:order
            },
            {
                headers: {
                    //Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }



        )
            .then(response=>{
                setResult(response.data);
            })
            .catch(error =>{
                console.log(error);

                if (error.code === "ERR_BAD_REQUEST") {



                }
            });




    }
    //@todo data loading animation, add adptivity
    return (
        <>
            <div className="background"></div>
            <div className="dash-container">
                <div className="table-title">
                    <img src='/images/Logo-header.svg'/>
                    <img src='/images/logo-text.png'/>
                    <span className="t-title"> ТАБЛИЦА РЕЗУЛЬТАТОВ</span>
                </div>
                <div className="table-container">
                <table>
                <thead>
                        <tr>
                            <th><a className={orderID==1?'ordered':orderID==2?'ordered2':''}      onClick={order_by_id}  >№</a></th>
                            <th>ФИО</th>
                            <th>КОМАНДА</th>
                            <th><a className={orderRes==1?'ordered':orderRes==2?'ordered2':''}    onClick={order_by_res}  >РЕЗУЛЬТАТ</a></th>
                            <th><a className={orderTime==1?'ordered':orderTime==2?'ordered2':''}  onClick={order_by_time}  >ВРЕМЯ</a></th>
                        </tr>
                    </thead>
                    <tbody>
                    {result.map((o, index)=>(
                    <tr>
                        <td>{index+1}</td>
                        <td>{o.fio}</td>
                        <td>{o.team}</td>
                        <td className={o.flight_result}>{(o.flight_result==='success') ? 'Успех' : (o.flight_result==='loss') ? 'Время вышло' :'Отмена'}</td>
                        <td>{o.flight_time}</td>
                    </tr>

                        ))}

                    </tbody>
                </table>
                </div>
            </div>
        </>
    )


}

export default Dashboard;
